/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { isEmpty } from "lodash";

function SEO({
  description,
  lang,
  meta,
  title,
  ogimage,
  header: { siteTitle },
  seoData,
  uri,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            fbAppId
          }
        }
        wp {
          seo {
            webmaster {
              googleVerify
            }
            social {
              facebook {
                defaultImage {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `
  );

  if (isEmpty(seoData)) {
    seoData = {
      title: "",
      metaDesc: "",
      opengraphDescription: "",
      twitterDescription: "",
      opengraphTitle: "",
      twitterTitle: "",
      openGraphImage: {
        sourceUrl: "",
      },
      twitterImage: {
        sourceUrl: "",
      },
    };
  }

  if (!isEmpty(seoData) && isEmpty(seoData.title)) {
    seoData.title = "";
  }

  if (!isEmpty(seoData) && isEmpty(seoData.metaDesc)) {
    seoData.metaDesc = "";
  }

  if (!isEmpty(seoData) && isEmpty(seoData.opengraphDescription)) {
    seoData.opengraphDescription = "";
  }

  if (!isEmpty(seoData) && isEmpty(seoData.twitterDescription)) {
    seoData.twitterDescription = "";
  }

  if (!isEmpty(seoData) && isEmpty(seoData.opengraphTitle)) {
    seoData.opengraphTitle = "";
  }

  if (!isEmpty(seoData) && isEmpty(seoData.twitterTitle)) {
    seoData.twitterTitle = "";
  }
  //console.log(seoData);
  if (
    !isEmpty(seoData) &&
    (isEmpty(seoData.openGraphImage) ||
      isEmpty(seoData.openGraphImage.sourceUrl))
  ) {
    seoData.openGraphImage = {
      sourceUrl: "https://shop.shroom4you.com/ogimg.jpg",
    };
  }

  if (
    !isEmpty(seoData) &&
    (isEmpty(seoData.twitterImage) || isEmpty(seoData.twitterImage.sourceUrl))
  ) {
    seoData.twitterImage = {
      sourceUrl: "https://shop.shroom4you.com/ogimg.jpg",
    };
  }

  const seoTitle = seoData.title || title;
  const seoDescription =
    seoData.metaDesc || description || site.siteMetadata.description;
  const ogDesc = seoData.opengraphDescription || seoDescription;
  const twitterDesc = seoData.twitterDescription || seoDescription;
  const ogTitle = seoData.opengraphTitle || seoTitle;
  const twitterTitle = seoData.twitterTitle || seoTitle;
  const ogImage = ogimage?.sourceUrl || seoData.openGraphImage?.sourceUrl;
  // const googleVerify = seo.webmaster.googleVerify || "";
  const ogURL = `${site.siteMetadata.siteUrl}${uri || ""}`;
  // const fbAppId = site.siteMetadata.fbAppId;
  const canonical = `${site.siteMetadata.siteUrl}${uri || "/"}`;
  //console.log(ogImage);
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seoTitle}
      titleTemplate={`%s | ${siteTitle}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
              {
                rel: "icon",
                href: "/favicon-32x32.png",
                type: "image/png",
              },
            ]
          : [
              {
                rel: "icon",
                href: "/favicon-32x32.png",
                type: "image/png",
              },
            ]
      }
      meta={[
        {
          name: `title`,
          content: seoTitle,
        },
        {
          name: `description`,
          content: seoDescription,
        },
        {
          property: `og:locale`,
          content: `pl_PL`,
        },
        {
          property: `og:url`,
          content: ogURL,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:site_name`,
          content: `Shroom`,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: "Shroom Drink",
        },
        {
          name: `twitter:title`,
          content: twitterTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDesc,
        },
        {
          name: `robots`,
          content: `index, follow`,
        },
        /*{
          name: `google-site-verification`,
          content: googleVerify,
        },
        {
          property: `fb:app_id`,
          content: fbAppId,
        },*/
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  description: ``,
  lang: `pl`,
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  header: PropTypes.object,
};

export default SEO;
